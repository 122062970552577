import { useState } from "react";
import Container from "react-bootstrap/Container";
import PlayerBlackList from "../PlayerBlackList.js";
import TeamSelect from "../TeamSelect.js";
import PlayersPowerList from "../PlayersPowerList.js";

function PlayerList() {
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [blacklist, setBlackList] = useState([]);

	const handleBlacklistChange = function(blacklist) {
		setBlackList(blacklist);
	};

	const handleTeamSelectChange = function(team) {
		setSelectedTeam(team);
	};

    return (
        <Container>
            <TeamSelect onChange={handleTeamSelectChange} />
            <br />
            <PlayerBlackList onChange={handleBlacklistChange} />
            <br />
            <div className="justify-content-center" style={{display: "flex", flexFlow: "column nowrap"}}>
                <PlayersPowerList team={selectedTeam} blacklist={blacklist} />
            </div>
        </Container>
    );
}

export default PlayerList;
