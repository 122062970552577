import { useEffect, useState }  from "react";

let TEAMS = require("./data/teams.json");

let DATA_PROMISE;

function useSwgohData() {
	let [data, setData] = useState(null);

	useEffect(() => {
		if (!DATA_PROMISE) {
			DATA_PROMISE = fetch("/api/data")
				.then(response => response.json())
				.then(response => processData(response))
		}
		DATA_PROMISE.then(response => {
			setData(response);
		});
	}, []);

	return data;
}

function processData(apiData) {
	apiData.players.sort((a, b) => {
		return a.data.name.localeCompare(b.data.name);
	});

	const out = {
		teams: [],
		players: apiData.players,
	};

	TEAMS.forEach(team => {
		team.players = [];
		out.players.forEach(p => {
			const player_leaders = getToons(p, team.leaders);
			if (player_leaders.length) {
				let selectTopUnitsCount;
				if (team.type === "toons") {
					selectTopUnitsCount = 5;
				} if (team.type === "fleet") {
					switch (player_leaders[0].data.rarity) {
						case 7:
							selectTopUnitsCount = 8;
							break;
						case 6:
						case 5:
							selectTopUnitsCount = 7;
							break;
						default:
							selectTopUnitsCount = 6;
							break;
					}
				}

				// add player to array, with the best formation and info for each toon
				const units =
					getToons(p, team.leaders.concat(team.units))
					.sort((a, b) => {
						const a_is_leader = team.leaders.indexOf(a.data.name) !== -1;
						const b_is_leader = team.leaders.indexOf(b.data.name) !== -1;
						if (a_is_leader !== b_is_leader) {
							return a_is_leader > 0 ? -1 : 1;
						}
						return b.data.power - a.data.power;
					})
					.slice(0, selectTopUnitsCount);
				team.players.push({
					player: p,
					units: units,
					power: units.map(unit => unit.data.power).reduce((accumulator, currentValue) => accumulator + currentValue),
				});
			}
		});
		out.teams.push(team);
	});
	out.teams.sort((a, b) => {
		if (a.type !== b.type) {
			return a.type === "toons" ? -1 : 1;
		}
		if (a.gl !== b.gl) {
			return a.gl ? -1 : 1;
		}
		return a.name.localeCompare(b.name);
	});
	return out;
}

function getToons(player, toons) {
	const out = [];
	for (let i = 0; i < player.units.length; i++) {
		if (toons.indexOf(player.units[i].data.name) >= 0) {
			out.push(player.units[i]);
		}
	}
	// TODO: sort by power
	// console.log(out);
	return out;
}

export default useSwgohData;
