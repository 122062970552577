import { useState } from "react";

import "./PlayersPowerList.css";


function zetas(unit) {
    const zs = unit.data.ability_data.filter(a => a.is_zeta && a.ability_tier === a.tier_max).map(a => "Z").join("");
    return zs ? ` (${zs})` : "";
}

function PlayersPowerList(props) {
    const [showDetails, setShowDetails] = useState(true);
    const handleShowDetailsChange = function(e) {
        setShowDetails(!!e.target.checked);
    };

    if (!props.team) {
        return null;
    }
    const blacklist = props.blacklist || [];
    const non_blacklisted_players = props.team.players
        .filter(p => blacklist.indexOf(p.player.data.name) === -1)
        .sort((a, b) => {
            if (a.power === b.power) {
                return a.player.data.name.localeCompare(b.player.data.name);
            }
            return a.power - b.power;
        })
        .reverse()

    return (
        <>
            <label id="showDetailsCheckboxLabel" style={{display: "flex", alignItems: "center"}}>
                <input style={{verticalAlign: "center"}} type="checkbox" id="showDetailsCheckbox" checked={showDetails} onChange={handleShowDetailsChange} />
                &nbsp;
                mostrar detalles
            </label>

            <table id="playersTable" className={showDetails ? "with-details" : "no-details"}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Jugador</th>
                        <th className="detail">PG</th>
                        <th className="detail">Equipo</th>
                    </tr>
                </thead>

                <tbody>
                    {non_blacklisted_players.map((p, idx) => (
                        <tr className="player">
                            <td className="player-position">{"#" + (idx + 1)}</td>
                            <td className="player-name">{p.player.data.name}</td>
                            <td className="player-power detail">{Math.round(p.power / 1000)}k&nbsp;</td>
                            <td className="player-units detail">
                                {p.units.map(unit => (
                                    <div className="player-unit">
                                        <span className="unit-name"><a href={'https://swgoh.gg' + unit.data.url} rel="external noreferrer" target="_blank">{unit.data.name + zetas(unit)}</a></span>
                                        <span className="unit-rarity">{unit.data.rarity}*</span>
                                        <span className="unit-gear-level">
                                            g{unit.data.gear_level}
                                            {parseFloat(unit.data.relic_tier-2) >= 0 ? " r" + (unit.data.relic_tier-2) : "   "}
                                        </span>
                                        <span className="unit-speed">v{(unit.data.stats[5])}</span>
                                        <span className="unit-power">{Math.round(unit.data.power / 1000)}k</span>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );

}

export default PlayersPowerList;
