const customStyles = {
    control: (provided, state) => ({
        ...provided,
        color: "#999",
        background: "var(--form-control-background)",
        border: "none",
    }),
    option: (provided, state) => ({
        ...provided,
        color: "#999",
        background: state.isFocused ? "#333" : state.isSelected ? "#555" : "#111",
    }),
    menu: (provided, state) => ({
        ...provided,
        color: "#999",
        background: "#111",
    }),
    multiValue: (provided, state) => ({
        ...provided,
        background: "#333",
        color: "#999",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#999",
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: "#999",
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
    }),
    // multiValueRemove: (provided, state) => ({}),
};

export default customStyles;
