import useSwgohData from "./useSwgohData.js";
import { FaBan } from "react-icons/fa";

import Select from "react-select";
import customSelectStyles from "./SelectStyles.js";

function PlayerBlackList(props) {
	const data = useSwgohData();
	const players = (data ? data.players : null) || [];

	const PLAYER_OPTIONS = players.map(p => {
		return {value: p.data.name, label: p.data.name};
	});

	const handleChange = function(e) {
		props.onChange(e ? e.map(option => option.value) : []);
	};

	return (
		<>
			<span style={{display: "flex", alignItems: "center"}}>
				<span style={{flex: "0 0 auto", marginRight: "0.5em"}}><FaBan /></span>
				<span style={{flex: "1 1 auto"}}>
					<Select
						styles={customSelectStyles}
						options={PLAYER_OPTIONS}
						isMulti={true}
						placeholder="Excluir jugadores..."
						isSearchable={true}
						closeMenuOnSelect={false}
						onChange={handleChange}
					/>
				</span>
			</span>
		</>
	);
}

export default PlayerBlackList;
