import Container from "react-bootstrap/Container";
import PagePlayerList from "./Pages/PlayerList.js";

import './img/body-background.jpg';
import 'bootswatch/dist/darkly/bootstrap.min.css';
import "./App.css";


function App() {
	return (
		<Container>
			<div className="main-container">
				<header>
					<h3>Dark Side of Endor's Moon</h3>
				</header>


				<PagePlayerList />

			</div>
		</Container>
	);
}

export default App;
