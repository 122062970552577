import { useEffect, useState }  from "react";
import Select from "react-select";
import customSelectStyles from "./SelectStyles.js";
import useSwgohData from "./useSwgohData.js";
import { FaTelegramPlane, FaUserShield, FaUsers } from "react-icons/fa";

import "./TeamSelect.css";

function TeamSelect(props) {
    const [loading, setLoading] = useState(true);
    const [teamOptions, setTeamOptions] = useState([]);

    const data = useSwgohData();
    useEffect(() => {
        setLoading(!data);
        if (data && data.teams) {
            const iconStyle = {
                marginRight: "0.5em",
            };
            const options = data.teams.map(t => {
                let containerClassName = "";
                if (t.gl) {
                    containerClassName = "team-select-legend";
                } else if (t.type === "toons") {
                    containerClassName = "team-select-toons";
                } else if (t.type === "fleet") {
                    containerClassName = "team-select-fleet";
                }

                const icon = t.type === "toons" ? <FaUserShield style={iconStyle} /> : <FaTelegramPlane style={iconStyle} />;
                return {team: t, value: t.name, label: (
                    <span className={containerClassName + " team-select-option"}>
                        {icon}
                        <span className="team-select-team-name">
                            {t.gl ? "GL " : ""}{t.name}
                        </span>
                        <span className="team-select-players-count">{t.players.length}&nbsp;<FaUsers /></span>
                    </span>)};
            });

            options.sort((a, b) => {
                if (a.team.gl !== b.team.gl) {
                    return a.team.gl ? -1 : 1;
                }
                if (a.team.type !== b.team.type) {
                    return a.team.type === "toons" ? -1 : 1;
                }
                return a.team.name.localeCompare(b.team.name);
            });
            setTeamOptions(options);
        }
    }, [data]);

	const handleChange = function(option) {
        props.onChange(option ? option.team : null);
	};

	return (
		<>
			<Select
				styles={customSelectStyles}
				options={teamOptions}
				placeholder={loading ? "cargando..." : "Elegí un equipo..."}
				isSearchable={true}
				onChange={handleChange}
			/>
		</>
	);
}

export default TeamSelect;
